import React, { useRef } from 'react';

const MailchimpForm = ({ onValidated }) => {
    const formRef = useRef();

    return (
        <div ref={formRef}>
            <div id="mc_embed_shell">
                <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                <div id="mc_embed_signup">
                    <form action="https://trainme.us22.list-manage.com/subscribe/post?u=1279de1e3e598c96b2fc17364&amp;id=0d8eaedac3&amp;f_id=00d4d3e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
                        <div id="mc_embed_signup_scroll"><h2 className="title-text" style={{ display: "none" }}>Receba novidades no email</h2>
                            <div class="indicates-required" style={{ display: "none" }}><span class="asterisk">*</span> indica obrigatório</div>
                            <div class="mc-field-group"><label for="mce-EMAIL" style={{ display: "none" }}>Seu melhor e-mail <span class="asterisk">*</span></label>
                                <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" placeholder='Insira seu email aqui' value="" /></div>
                            <div hidden="">
                                <input type="hidden" name="tags" value="10758" /></div>
                            <div id="mce-responses" class="clear" style={{ display: "none" }}>
                                <div class="response" id="mce-error-response" style={{ display: "none" }}></div>
                                <div class="response" id="mce-success-response" style={{ display: "none" }}></div>
                            </div><div aria-hidden="true" style={{ position: "absolute", left: "-5000px", display: "none" }}>
                                <input type="text" name="b_1279de1e3e598c96b2fc17364_0d8eaedac3" tabindex="-1" value="" /></div><div class="clear">
                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Insreva-se" /></div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default MailchimpForm;
