import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import titleNinen from "../../data/title/title-nine.json";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailchimpForm from "../MailChimp/MailChimp";

const [{ title }] = titleNinen;
const NewsLeter = ({ status, message, onValidated }) => {

  return (
    <React.Fragment>
      <div className="newsletter-section text-center sec-ptb-160 pt-0 clearfix">
        <Container>
          <div className="section-title text-center mb-60">
            <h2 className="title-text">{title}</h2>
          </div>

          <Row className="justify-content-center">
            <Col lg="8" md="10">
              <div
                className="newsletter-form mb-30"
                style={{
                  backgroundImage: `url(${"assets/images/newsletter-bg-1.png"})`,
                }}
              >
                {/* <form> */}
                <div className="form-item mb-0">


                  <MailchimpForm />

                </div>
                {/* </form> */}
              </div>
            </Col>
          </Row>


          <p className="mb-0">Prometemos não enviar spam (juramos!)</p>
        </Container>
      </div>
    </React.Fragment>
  );
};

class SubscribeMailChimp extends Component {
  render() {
    const url =
      "https://jster.us7.list-manage.com/subscribe/post?u=ed40c0084a0c5ba31b3365d65&id=ec6f32bf5e";
    return (
      <div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <NewsLeter
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </div>
    );
  }
}

export default SubscribeMailChimp;
